import React from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import App from "./App"
import {Provider} from "react-redux"
import {store} from "./store"
import {locale} from "moment"
import "moment/locale/ru"
import {ConfigProvider} from "antd"
import ruRU from "antd/lib/locale-provider/en_US"
import "./index.less"
import {Auth0Provider} from "@auth0/auth0-react"

locale("ru")

if (!(process.env.REACT_APP_AUTH_DOMAIN && process.env.REACT_APP_AUTH_CLIENT_ID)) throw Error("Error Auth0")

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider locale={ruRU}>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                    authorizationParams={{
                        audience: process.env.REACT_APP_AUTH_AUDIENCE,
                        redirect_uri: window.location.origin
                    }}
                >
                    <App />
                </Auth0Provider>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
