import {createAsyncThunk} from "@reduxjs/toolkit"
import {DOMAIN_API, TOKEN} from "utils/api"
import {AppThunkProps} from "store"
import {Message} from "types/Message"

interface ReturnedType {
    message: Message
}

interface AgrProps {
    platform: "bot" | "app"
    title: string
    header?: string
    message: string
    image?: string
    video?: string
    disable_notification: "1" | null
    buttons: {
        type: "link" | "scene" | "web_app"
        title: string
        action: string
    }[]
}

export const createMessage = createAsyncThunk<ReturnedType, AgrProps, AppThunkProps>(
    "message/create",
    async (data, {signal}) => {
        // Запрос
        const response = await fetch(`${DOMAIN_API}/user/message`, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            },
            signal
        })
        return await response.json()
    }
)
