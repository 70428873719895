import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {DOMAIN_API} from "utils/api"
import {Message} from "types/Message"
import {Moment} from "moment"
import { getCookie } from "utils/cookie"

interface AgrProps {
    dateFrom?: Moment
    dateTo?: Moment
    page: number
}

type ReturnedType = {
    messages: {
        total: number
        results: Message[]
    }
}

export const fetchMessages = createAsyncThunk<ReturnedType, AgrProps, AppThunkProps>(
    "message/fetch",
    async ({dateFrom, dateTo, page}, {signal}) => {
        const TOKEN = getCookie("crm_token_access");
        if (!TOKEN) {
            // Обработка случая, когда токен недоступен
            throw new Error("Token not available");
        }
        // Запрос
        const response = await fetch(`${DOMAIN_API}/user/messages`, {
            method: "post",
            body: JSON.stringify({dateFrom, dateTo, page}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            },
            signal
        })
        return await response.json()
    }
)
