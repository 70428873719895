import React from "react"
import Layout from "./layouts/Layout"
import Loader from "./components/loader/Loader"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import {withAuthenticationRequired} from "@auth0/auth0-react"
import {useGetToken} from "./hooks/useGetToken"

const Home = React.lazy(() => import("./pages/Home"))

function App() {
    const {loading} = useGetToken()

    if (loading) return <Loader>Загрузка...</Loader>

    return (
        <React.Suspense fallback={<Loader>Загрузка...</Loader>}>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route index element={<Home />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </React.Suspense>
    )
}

export default withAuthenticationRequired(App, {onRedirecting: () => <Loader />})
