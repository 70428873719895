import {Avatar, Layout, Modal} from "antd"
import React from "react"
import styles from "./Layout.module.less"
import {LogoutOutlined} from "@ant-design/icons"
import {useAuth0} from "@auth0/auth0-react"

const {Header, Content} = Layout

interface LayoutContainerProps {
    children?: React.ReactNode;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({children}) => {
    const {user, logout} = useAuth0()

    const onLogoutHandler = () => {
        Modal.confirm({
            title: "Вы уверены что хотите выйти?",
            onOk: () => {
                logout({logoutParams: {logoutParams: window.location.origin}})
            }
        })
    }

    return (
        <Layout className={styles.mainLayout}>
            <Layout className={styles.secondLayout}>
                <Header className={styles.header}>
                    <div className={styles.profile}>
                        <div>
                            <Avatar style={{backgroundColor: "#f56a00", verticalAlign: "middle"}} size="large" gap={2}>
                                {user?.name || "Гость"}
                            </Avatar>
                        </div>
                        <div className={styles.fullName}>
                            <span>{user?.given_name || "Гость"}</span>
                        </div>
                    </div>
                    <div className={styles.nav}>
                        <div className={styles.item}
                             onClick={onLogoutHandler}>
                            <LogoutOutlined /> Выйти
                        </div>
                    </div>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 16px",
                        minHeight: 280
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutContainer
