import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {DOMAIN_API, TOKEN} from "utils/api"

interface AgrProps {
    token: string
}

type ReturnedType = {
    status: string
    token: string
}

export const editMindboxAuthToken = createAsyncThunk<ReturnedType, AgrProps, AppThunkProps>(
    "mindbox-auth-token/edit",
    async (data, {signal}) => {
        // Запрос
        const response = await fetch(`${DOMAIN_API}/user/admin/mindbox/token`, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            },
            signal
        })
        return await response.json()
    }
)
