import {createAsyncThunk} from "@reduxjs/toolkit"
import {DOMAIN_API, TOKEN} from "utils/api"
import {AppThunkProps} from "store"
import {Message} from "types/Message"

interface ReturnedType {
    message: Message
}

interface AgrProps {
    id: number
    data: {
        platform: "bot" | "app"
        title: string
        header?: string
        text: string
        url_image?: string
        buttons: {
            type: "link" | "scene" | "web_app"
            title: string
            action: string
        }[]
    }
}

export const editMessage = createAsyncThunk<ReturnedType, AgrProps, AppThunkProps>(
    "message/edit",
    async ({id, data}, {signal}) => {
        // Запрос
        const response = await fetch(`${DOMAIN_API}/user/message/${id}`, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            },
            signal
        })
        return await response.json()
    }
)
