import {getCookie, setCookie} from "../utils/cookie"
import {useAuth0} from "@auth0/auth0-react"
import {useEffect, useState} from "react"

type UseGetTokenType = () => {token?: string, loading: boolean}

export const useGetToken: UseGetTokenType = () => {
    const {getAccessTokenSilently} = useAuth0()
    const [token, setToken] = useState(getCookie("crm_token_access"))
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!token) {
            setLoading(true)
            getAccessTokenSilently({detailedResponse: true})
                .then(r => {
                    setToken(r.access_token)
                    const daysExpires = r.expires_in / 86400
                    setCookie("crm_token_access", r.access_token, {expires: daysExpires})
                })
            setLoading(false)
        }
    }, [getAccessTokenSilently, token])

    return {token, loading}
}
