import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {DOMAIN_API, TOKEN} from "utils/api"

type ReturnedType = {
    token: string
}

export const fetchMindboxAuthToken = createAsyncThunk<ReturnedType, undefined, AppThunkProps>(
    "mindbox-auth-token/fetch",
    async (_, {signal}) => {
        // Запрос
        const response = await fetch(`${DOMAIN_API}/user/admin/mindbox/token`, {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            },
            signal
        })
        return await response.json()
    }
)
