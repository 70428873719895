import React from "react"
import styles from "./Loader.module.less"
import {LoadingOutlined} from "@ant-design/icons"

interface LoaderProps {
    children?: React.ReactNode
}

const Loader: React.FC<LoaderProps> = ({children}) => {
    return (
        <div className={styles.loader}>
            <div className={styles.icon}>
                <LoadingOutlined />
            </div>
            {children}
        </div>
    )
}

export default Loader
