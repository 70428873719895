import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {StoreState} from "store"
import {useSelector} from "react-redux"
import {Message} from "types/Message"
import {fetchMessages} from "./fetchMessages"
import {createMessage} from "./createMessage"
import {editMessage} from "./editMessage"

export const messageAdapter = createEntityAdapter<Message>({
    sortComparer: (a, b) => (a.id < b.id ? 1 : -1)
})

export interface StateProps {
    loading: boolean
    pagination: {
        current: number
        total: number
    }
}

const initialState = messageAdapter.getInitialState<StateProps>({
    loading: true,
    pagination: {
        current: 1,
        total: 0
    }
})

const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        changePage: (state, action: PayloadAction<number>) => {
            state.pagination.current = action.payload
        }
    },
    extraReducers: builder => {
        // Создание рассылки
        builder.addCase(createMessage.pending, state => {
            state.loading = true
        })
        builder.addCase(createMessage.fulfilled, (state, action) => {
            messageAdapter.setOne(state, action.payload.message)
            state.loading = false
        })
        builder.addCase(createMessage.rejected, state => {
            state.loading = false
        })
        // Редактирование рассылки
        builder.addCase(editMessage.pending, state => {
            state.loading = true
        })
        builder.addCase(editMessage.fulfilled, (state, action) => {
            messageAdapter.setOne(state, action.payload.message)
            state.loading = false
        })
        builder.addCase(editMessage.rejected, state => {
            state.loading = false
        })
        // Вывод всех рассылок
        builder.addCase(fetchMessages.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchMessages.fulfilled, (state, action) => {
            messageAdapter.removeAll(state)
            messageAdapter.upsertMany(state, action.payload?.messages?.results || [])
            state.pagination.total = action.payload?.messages?.total || 0
            state.loading = false
        })
        builder.addCase(fetchMessages.rejected, state => {
            state.loading = false
        })
    }
})

export const {selectById, selectAll} = messageAdapter.getSelectors<StoreState>(
    state => state.message
)

export const {changePage} = messageSlice.actions

export const usePaginationMessages = () => useSelector((state: StoreState) => state.message.pagination)

// Загрузка
export const useLoadingMessages = () => useSelector((state: StoreState) => state.message.loading)

// Вывод всех заявок
export const useSelectAllMessages = () => useSelector(selectAll)

export default messageSlice.reducer
