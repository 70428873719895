import {createSlice} from "@reduxjs/toolkit"
import {fetchMindboxAuthToken} from "./fetchMindboxAuthToken"
import {editMindboxAuthToken} from "./editMindboxAuthToken"
import {useSelector} from "react-redux"
import {StoreState} from "../../store"


export interface StateProps {
    token: string | null
    loading: boolean
}

const initialState: StateProps = {
    token: null,
    loading: false
}

const mindboxAuthTokenSlice = createSlice({
    name: "mindbox-auth-token",
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Получение токена
        builder.addCase(fetchMindboxAuthToken.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchMindboxAuthToken.fulfilled, (state, action) => {
            state.token = action.payload.token
            state.loading = false
        })
        builder.addCase(fetchMindboxAuthToken.rejected, (state) => {
            state.loading = false
        })

        // Изменить токен
        builder.addCase(editMindboxAuthToken.pending, (state) => {
            state.loading = true
        })
        builder.addCase(editMindboxAuthToken.fulfilled, (state, action) => {
            state.token = action.payload.token
            state.loading = false
        })
        builder.addCase(editMindboxAuthToken.rejected, (state) => {
            state.loading = false
        })
    }
})

export default mindboxAuthTokenSlice.reducer

export const useMindboxAuthToken = () => useSelector((state: StoreState) => state.mindboxAuthToken)
